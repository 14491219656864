import { z } from "zod";

/**
 * Should contain all active Posthog events, used to capture user behavior.
 * Format: <action>_<category>_<object>
 * - action: verb in past tense to describe what happened, e.g. clicked, opened, logged, updated, created, deleted...
 * - category: context the event took place in, e.g. signup, block, project, ...
 * - object (optional): noun that refers to the component or location the event took, e.g. continue_button, payment_link, ...
 */
export enum UserEvent {
  // Auth events
  ClickedSignupContinueButton = "clicked_signup_continue_button",
  ClickedSignupButton = "clicked_signup_button",
  ClickedSignupGoogleButton = "clicked_signup_google_button",
  ClickedLoginButton = "clicked_login_button",
  ClickedLoginGoogleButton = "clicked_login_google_button",
  ClickedLoginMagicLinkButton = "clicked_login_magic_link_button",
  ClickedLoginSendMagicLinkButton = "clicked_login_send_magic_link_button",
  LoggedIn = "logged_in",
  LoggedOut = "logged_out",
  // Subscription events
  OpenedSubscriptionPaymentLink = "opened_subscription_payment_link",
  OpenedSubscriptionRenewalModal = "opened_subscription_renewal_modal",
  ClickedSubscriptionRenewalButton = "clicked_subscription_renewal_button",
  CompletedSubscriptionCheckoutSession = "completed_subscription_checkout_session",
  RenewedSubscription = "renewed_subscription",
  CanceledSubscription = "canceled_subscription",
  SetSubscriptionCancelation = "set_subscription_cancelation",
  UnsetSubscriptionCancelation = "unset_subscription_cancelation",
  PausedSubscription = "paused_subscription",
  // Knowledge events
  CreatedBlock = "created_block",
  UpdatedBlock = "updated_block",
  OpenedBlockHistory = "opened_block_history",
  RevertedBlockHistory = "reverted_block_version",
  OpenedBlockRegenerateOptions = "opened_block_regenerate_options",
  ClickedBlockRegenerateCustom = "clicked_block_regenerate_custom",
  RegeneratedBlock = "regenerated_block",
  ClickedKnowledgeEditStacksButton = "clicked_knowledge_edit_stacks_button",
  ClickedKnowledgeExploreStacksButton = "clicked_knowledge_explore_stacks_button",
  // Asset events
  ClickedAssetSuggestion = "clicked_asset_suggestion",
  CreatedAsset = "created_asset",
  UpdatedAssetResult = "updated_asset_result",
  UpdatedAssetInstructions = "updated_asset_instructions",
  DeletedAsset = "deleted_asset",
  // Toggle settings events
  ToggledFeature = "toggled_feature",
  // Project events
  UpdatedProject = "updated_project",
  // User events
  UpdatedUserProfile = "updated_user_profile",
}

export enum SubscriptionType {
  None = "None",
  Trial = "Trial",
  Paid = "Paid",
}

export enum EventSource {
  ActiveCampaign = "active-campaign",
  Posthog = "posthog",
}

const EventPropertiesSchema = z.record(z.string(), z.unknown());

export const TrackEventPayloadSchema = z.object({
  eventName: z.nativeEnum(UserEvent),
  properties: EventPropertiesSchema,
  sources: z.array(z.nativeEnum(EventSource)),
});
export type TrackEventPayload = z.infer<typeof TrackEventPayloadSchema>;
