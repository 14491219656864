import {
  ActionIcon,
  ActionIconProps,
  CopyButton,
  Tooltip,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import type { ReactNode } from "react";

export function TextCopier({
  content,
  disabled = false,
  children,
  iconProps,
  iconSize = 14,
}: {
  content: string;
  disabled?: boolean;
  children?: (copy: () => void, copied?: boolean) => ReactNode;
  iconProps?: ActionIconProps;
  iconSize?: number;
}) {
  return (
    <CopyButton value={content}>
      {({ copy, copied }) => (
        <Tooltip
          withArrow
          label={copied ? "Copied to your clipboard" : "Copy to clipboard"}
        >
          {children ? (
            children(copy, copied)
          ) : (
            <ActionIcon
              disabled={disabled}
              color={copied ? "green" : "dimmed"}
              variant="subtle"
              onClick={(e) => {
                e.stopPropagation();
                copy();
              }}
              {...(iconProps ?? {})}
            >
              {copied ? (
                <IconCheck size={iconSize} />
              ) : (
                <IconCopy size={iconSize} />
              )}
            </ActionIcon>
          )}
        </Tooltip>
      )}
    </CopyButton>
  );
}
