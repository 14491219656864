import {
  Group,
  Popover,
  Stack,
  Text,
  ThemeIcon,
  type PopoverProps,
} from "@mantine/core";
import { IconBinocularsFilled } from "@tabler/icons-react";
import { FeatureFlag, OnboardingCalloutKey } from "core";
import { useCallback, useMemo } from "react";
import { useIdentity } from "../../auth/useIdentity";
import { useIdentityMutation } from "../../auth/useIdentityMutation";
import { trpc } from "../../utils/trpc";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import classes from "./OnboardingCallout.module.css";

const onboardingCallouts: Record<
  OnboardingCalloutKey,
  { text: string; oneOff?: boolean }
> = {
  // Navigation:
  [OnboardingCalloutKey.Dashboard]: {
    text: "The dashboard is the daily snapshot of your business",
  },
  [OnboardingCalloutKey.Build]: {
    text: "This is where you add and develop your business knowledge in Smart Blocks to build your knowledge system",
  },
  [OnboardingCalloutKey.Create]: {
    text: "Create Mode references all your business knowledge to create accurate content",
  },
  [OnboardingCalloutKey.Ideas]: {
    text: "Have an idea you're not yet ready to implement? Add it to Ideas!",
  },

  // Build page:
  [OnboardingCalloutKey.SmartBlocks]: {
    text: "Build your business knowledge in Smart Blocks",
  },
  [OnboardingCalloutKey.Stacks]: {
    text: "Smart Blocks are grouped into Stacks, relevant Stacks have been added based on your business. You can add more in Explore.",
  },

  // Firsts:
  [OnboardingCalloutKey.FirstBlockOpen]: {
    oneOff: true,
    text: `
      At any time you can collaborate with your assistant - it knows your business,
      everything about the world, and the context of what you are working on right now.
    `,
  },
  [OnboardingCalloutKey.FirstCreate]: {
    oneOff: true,
    text: `
      You can collaborate with your assistant about what it just created, ask it questions on
      how to improve it, or even edit and change it.
    `,
  },
};

const onboardingCalloutOrder: OnboardingCalloutKey[] = [
  OnboardingCalloutKey.Dashboard,
  OnboardingCalloutKey.Build,
  OnboardingCalloutKey.Create,
  OnboardingCalloutKey.Ideas,
  OnboardingCalloutKey.SmartBlocks,
  OnboardingCalloutKey.Stacks,
];

type CalloutId = keyof typeof onboardingCallouts;

interface OnboardingCalloutProps {
  calloutId: CalloutId;
  children: (() => JSX.Element) | JSX.Element;

  /**
   * Depending on the inner element, we may need to wrap it with a div
   * so it's usable as a popover target.
   */
  wrapTarget?: boolean;

  position?: PopoverProps["position"];

  /**
   * In some cases like the side navigation we may want to disable this to make
   * scroll effects more natural
   */
  withinPortal?: boolean;

  /** How much horizontal space the popover will take up */
  maxWidth?: number;

  /** If set, this callout is not rendered regardless of every other condition. */
  forceHidden?: boolean;
}

function getNextCalloutKeyAfter(
  calloutKey: OnboardingCalloutKey | undefined,
): OnboardingCalloutKey | null {
  if (!calloutKey) {
    return onboardingCalloutOrder.at(0)!;
  }

  const idx = onboardingCalloutOrder.findIndex((k) => k === calloutKey);

  if (idx === -1) {
    return onboardingCalloutOrder.at(0)!;
  }

  const nextCalloutKey = onboardingCalloutOrder.at(idx + 1);
  return nextCalloutKey ?? null;
}

export function OnboardingCallout({
  calloutId,
  children,
  wrapTarget = true,
  position = "top",
  withinPortal = true,
  maxWidth = 400,
  forceHidden = false,
}: OnboardingCalloutProps) {
  const isEnabled = useIsFeatureEnabled(FeatureFlag.UseOnboardingBubbles);
  const { identity } = useIdentity();
  const innerContent = typeof children === "function" ? children() : children;

  const { updateIdentity } = useIdentityMutation();
  const utils = trpc.useUtils();

  /**
   * Find the last onboarding callout displayed based on the user's settings,
   * and figure out if the current callout is up next:
   */
  const lastCallout = identity?.settings.onboardingCalloutProgress;
  const nextCallout = useMemo(
    () => getNextCalloutKeyAfter(lastCallout),
    [lastCallout],
  );

  const details = onboardingCallouts[calloutId];

  const trackOnboardingProgress = useCallback(() => {
    if (!identity) {
      return false;
    }

    updateIdentity({
      id: identity.id,
      settings: {
        ...(details.oneOff ? {} : { onboardingCalloutProgress: calloutId }),
        ...(details.oneOff
          ? {
              onboardingCalloutOneOff: [
                ...new Set([
                  ...(identity.settings.onboardingCalloutOneOff || []),
                  calloutId,
                ]),
              ] as OnboardingCalloutKey[],
            }
          : {}),
      },
    });

    /**
     * Optimistically update the cache
     */
    utils.identity.get.setData(undefined, (cachedIdentity) => {
      if (!cachedIdentity?.identity) {
        return cachedIdentity;
      }

      const newSettings = {
        ...cachedIdentity.identity.settings,

        ...(details.oneOff
          ? {
              onboardingCalloutOneOff: [
                ...new Set([
                  ...(cachedIdentity.identity.settings
                    .onboardingCalloutOneOff || []),
                  calloutId,
                ]),
              ],
            }
          : { onboardingCalloutProgress: calloutId }),
      };

      return {
        ...cachedIdentity,
        identity: {
          ...cachedIdentity.identity,
          settings: newSettings,
        },
      };
    });
  }, [identity, updateIdentity, calloutId, utils, details]);

  const isActiveOneOff =
    details.oneOff &&
    (!identity?.settings?.onboardingCalloutOneOff ||
      identity?.settings?.onboardingCalloutOneOff?.findIndex(
        (k) => k === calloutId,
      ) === -1);

  if (
    !isEnabled ||
    forceHidden ||
    (nextCallout !== calloutId && !isActiveOneOff)
  ) {
    return innerContent;
  }

  return (
    <Popover
      opened
      position={position}
      withArrow
      arrowSize={15}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withinPortal={withinPortal}
      keepMounted={false}
      shadow="md"
      classNames={{
        dropdown: classes.calloutDropdown,
        arrow: classes.calloutArrow,
      }}
      arrowOffset={20}
    >
      <Popover.Target>
        {wrapTarget ? <div>{innerContent}</div> : innerContent}
      </Popover.Target>
      <Popover.Dropdown maw={maxWidth} onClick={trackOnboardingProgress}>
        <Group gap={5} preventGrowOverflow wrap="nowrap" align="flex-start">
          <ThemeIcon variant="transparent" c="teal.8" size="lg">
            <IconBinocularsFilled />
          </ThemeIcon>

          <Stack gap={5} w="100%">
            <Text w="100%" size="sm" c="black" fw={500}>
              {details.text.trim()}
            </Text>

            <Text ta="left" size="xs" c="teal.6" mt="sm">
              Click here to continue
            </Text>
          </Stack>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}
