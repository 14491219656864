import classes from "./StackIcons.module.css";

export function DesignIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M64.3271 59.8947L91.9422 49.0327L133.829 94.1025C138.249 98.8577 139.765 105.624 137.798 111.811L122.607 159.585L102.136 108.476C105.124 106.585 107.108 103.252 107.108 99.4543C107.108 93.5633 102.332 88.7877 96.4414 88.7877C90.5504 88.7877 85.7747 93.5633 85.7747 99.4543C85.7747 105.345 90.5504 110.121 96.4414 110.121C97.115 110.121 97.774 110.059 98.413 109.939L118.931 161.167L74.9256 136.455C69.3812 133.341 65.891 127.533 65.744 121.176L64.3271 59.8947Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.527 69.8125L204.526 154.363C204.526 166.542 198.029 177.795 187.481 183.885L114.258 226.16C106.833 230.448 97.6839 230.448 90.2583 226.16L11.9903 180.972C4.56467 176.685 -0.00969722 168.762 -0.00971792 160.188L-0.00976562 75.6366C-0.00974545 63.4576 6.48776 52.2038 17.035 46.1144L90.2592 3.8396C97.6848 -0.4475 106.833 -0.447512 114.259 3.83957L192.527 49.0279C199.953 53.3151 204.527 61.2382 204.527 69.8125ZM83.3377 27.5169L98.259 18.9023C100.734 17.4733 103.784 17.4733 106.259 18.9023L183.482 63.4873C185.958 64.9164 187.482 67.5575 187.482 70.4156L187.481 159.585C187.481 162.443 185.957 165.084 183.482 166.513L106.259 211.098C103.783 212.527 100.734 212.527 98.2585 211.098L21.035 166.513C18.5598 165.084 17.035 162.443 17.035 159.585L17.0349 70.4147C17.0349 67.5566 18.5597 64.9155 21.035 63.4865L57.3514 42.5197L63.1253 57.1988L90.7403 46.3368L83.3377 27.5169Z"
        fill="currentColor"
      />
    </svg>
  );
}
