import { generateColors } from "@mantine/colors-generator";
import {
  createTheme,
  MantineColor,
  MantineProvider,
  MantineThemeColors,
  MantineThemeColorsOverride,
  MantineThemeOverride,
} from "@mantine/core";
import { createContext, useState } from "react";
import { OnlyChildrenProps } from "../../utils/misc";
import { ZedIndex } from "../../utils/zedIndex";
import chipClasses from "../variants/Chip.module.css";

// Init mantine theme:
const mantineTheme = createTheme({
  fontFamily: "'Inter Variable', sans-serif",
  colors: {
    blue: [
      "#eaf0ff",
      "#d2dbfc",
      "#a3b4f6",
      "#718af2",
      "#4767ee",
      "#2f52ed",
      "#2146ed",
      "#1538d3",
      "#0c31bd",
      "#0029a7",
    ],
    yellow: [
      "#FBEBD5",
      "#F9CF7E",
      "#ECB837",
      "#CFA02E",
      "#AC8425",
      "#88691B",
      "#644C10",
      "#4C390C",
      "#2F240B",
      "#1A1507",
      "#1A1507",
    ],
    red: [
      "#FBEAE6",
      "#F9CBC0",
      "#F9AE9B",
      "#F8876F",
      "#F74D37",
      "#D2241B",
      "#A91A14",
      "#780F0B",
      "#481308",
      "#251006",
      "#251006",
    ],
    orange: [
      "#FBEAE2",
      "#FCCBB4",
      "#FEAC83",
      "#FE8647",
      "#EA5D04",
      "#BB4702",
      "#A33D02",
      "#692702",
      "#3F1B05",
      "#211105",
    ],
    teal: [
      "#DBFAF6",
      "#CBF7F2",
      "#8CE6DF",
      "#77D1C8",
      "#67B7AF",
      "#539891",
      "#407873",
      "#305C58",
      "#21423F",
      "#152A27",
      "#0D1E1B",
      "#0D1716",
      "#0D1716",
    ],
    base: [
      "#FFFFFF",
      "#F7F8F8",
      "#EDEEEE",
      "#D5D7D8",
      "#BFC1C3",
      "#A5A9AB",
      "#8A8C8E",
      "#6B6F70",
      "#505253",
      "#3B3D3D",
      "#252626",
      "#151616",
    ],
  },
  primaryShade: 3,
  defaultRadius: "md",
  shadows: {
    lg: "0 6px 6px rgba(0, 0, 0, .3)",
  },
  components: {
    Blockquote: {
      defaultProps: {
        p: "md",
        m: "lg",
        ta: "left",
      },
      styles: {
        root: {
          borderLeft: "none",
        },
      },
    },

    Button: {
      defaultProps: {
        loaderProps: {
          type: "oval",
        },
      },
    },

    Card: {
      defaultProps: {
        p: "lg",
        radius: "lg",
        shadow: "none",
        withBorder: true,
      },
    },

    Chip: {
      classNames: chipClasses,
      defaultProps: {
        radius: "md",
        variant: "filled",
        fw: 600,
      },
    },

    Overlay: {
      defaultProps: {
        zIndex: ZedIndex.Overlay,
      },
    },

    ProgressRoot: {
      defaultProps: {
        radius: "var(--mantine-radius-sm)",
      },
    },

    PasswordInput: {
      classNames: {
        error: "form-error",
        input: "form-textinput",
      },
    },

    TextInput: {
      classNames: {
        error: "form-error",
        input: "form-textinput",
      },
      styles: {
        error: {},
        label: {
          marginBottom: "3px",
        },
      },
    },

    Title: {
      defaultProps: {
        fw: "normal",
      },
    },

    Textarea: {
      defaultProps: {
        size: "md",
      },
    },

    Popover: {
      defaultProps: {
        withArrow: true,
        arrowSize: 15,
        shadow: "sm",
      },
    },

    Tooltip: {
      defaultProps: {
        offset: 10,
        color: "black",
      },
    },
  },
});

const defaultTheme = {
  ...mantineTheme,
  primaryColor: "orange",
  secondaryColor: "teal",
} as ThemeProps;

type UpdateColorProps = {
  colorHex: MantineColor;
  /**
   * Name of the color to be used as the identifier or a new color palette
   * and/or as the new color that override one of the theme
   * defaults (e.g. 'primaryColor').
   */
  colorName: string;
  /**
   * When true, will generate a new color palette corresponding to the provided
   * 'colorHex' prop.
   */
  generateColorPalette: boolean;
  /**
   * Valid mantine theme props that can be overriden.
   */
  overrideColorName?: "primaryColor" | "secondaryColor";
};

type ThemeProps = MantineThemeOverride & {
  colors: MantineThemeColors;
  secondaryColor: MantineColor;
};

interface ThemeContextProps {
  theme: ThemeProps;
  updateColors: (args: UpdateColorProps[]) => void;
  resetTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: defaultTheme,
  updateColors: () => {},
  resetTheme: () => {},
});

export function ThemeProvider({ children }: OnlyChildrenProps) {
  const [theme, setTheme] = useState<ThemeProps>(defaultTheme);

  const updateColors = (args: UpdateColorProps[]) => {
    // Generate new color palettes from given hex
    const newColors: MantineThemeColorsOverride = args.reduce((prev, color) => {
      if (color.generateColorPalette) {
        const palette = generateColors(color.colorHex);
        return {
          ...prev,
          [color.colorName]: palette,
        };
      }
      return { ...prev };
    }, {});
    // Override some theme colors with the new palette if specified
    const overrideThemeProps = args.reduce((prev, curr) => {
      return {
        ...prev,
        ...(curr.overrideColorName && {
          [curr.overrideColorName]: curr.colorName,
        }),
      };
    }, {});

    setTheme((current) => ({
      ...current,
      colors: {
        ...current.colors,
        ...newColors,
      },
      ...overrideThemeProps,
    }));
  };

  const resetTheme = () => {
    setTheme((current) => ({
      ...current,
      primaryColor: defaultTheme.primaryColor,
      secondaryColor: defaultTheme.secondaryColor,
    }));
  };

  return (
    <ThemeContext.Provider value={{ theme, updateColors, resetTheme }}>
      <MantineProvider theme={theme}>{children}</MantineProvider>
    </ThemeContext.Provider>
  );
}
