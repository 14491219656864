import {
  ActionIcon,
  AppShell,
  Avatar,
  Badge,
  Box,
  Burger,
  Button,
  Code,
  Container,
  Divider,
  Group,
  Image,
  Loader,
  Menu,
  ScrollArea,
  ScrollAreaAutosize,
  Skeleton,
  Spoiler,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconAssembly,
  IconBulb,
  IconChevronRight,
  IconHelp,
  IconHome,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconLogout,
  IconMoon,
  IconPencil,
  IconSchool,
  IconSettings,
  IconStack2,
  IconSun,
  IconSwitchHorizontal,
  IconUser,
  IconUsersGroup,
} from "@tabler/icons-react";
import clsx from "clsx";
import {
  FeatureFlag,
  IdentityRole,
  NoanError,
  OnboardingCalloutKey,
  UserEvent,
  isNoanError,
} from "core";
import dayjs from "dayjs";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo } from "react";
import {
  Link,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { useAuthContext, useOptionalAuthContext } from "../auth/useAuthContext";
import { useIdentity, useIsRole } from "../auth/useIdentity";
import { FirstTimeVideoModal } from "../components/FirstTimeVideo";
import { FullPageLoader } from "../components/FullPageLoader";
import { NavLink } from "../components/NavLink";
import { OnboardingCallout } from "../components/OnboardingCallout";
import { ReaderPanelContextProvider } from "../components/ReaderPanel/ReaderPanelContext";
import { useTheme } from "../components/ThemeProvider/useTheme";
import { NoanLogo } from "../components/icons/NoanLogo.svg";
import { isDeployed } from "../config";
import { useUpdateReferral } from "../utils/useAccountMutation";
import { useClientMode } from "../utils/useClientMode";
import { useIsFeatureEnabled } from "../utils/useIsFeatureEnabled";
import { useOrganization } from "../utils/useOrganization";
import { useOrganizationMutation } from "../utils/useOrganizationMutation";
import { useOrganizations } from "../utils/useOrganizations";
import { getUserProperties, useTrackEvent } from "../utils/useTrackEvent";
import { ZedIndex } from "../utils/zedIndex";
import classes from "./Layout.module.css";
import { topHeaderHeights, type LayoutVariant } from "./layoutConfig";

function isSubscribePage(pathname: string) {
  return pathname === "/subscribe";
}

function UserMenu({
  hideAccountInfo,
  hideOrganizations,
  variant = "default",
}: {
  hideAccountInfo: boolean;
  hideOrganizations?: boolean;
  variant?: "sidebar" | "default";
}) {
  const { signOut, user } = useOptionalAuthContext();
  const { identity } = useIdentity();
  const { data: organizations } = useOrganizations();
  const { switchOrganization, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });
  const navigate = useNavigate();
  const { setColorScheme } = useMantineColorScheme();
  const { track } = useTrackEvent();

  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });
  const isClient = useIsRole(IdentityRole.Client);

  function onSwitchOrganization(orgId: string) {
    switchOrganization({ id: orgId });
    const organization = organizations.find((o) => o.id === orgId);
    notifications.show({
      message: `Connected to project ${organization?.name}`,
      color: "green",
      icon: <IconSwitchHorizontal size={18} />,
    });
  }

  function onSignOut() {
    track(UserEvent.LoggedOut);
    signOut();
  }

  const loggedInOrganization = organizations.find(
    (o) => identity && o.id === identity?.organizationId,
  );
  const otherOrganizations = organizations.filter(
    (o) => identity && o.id !== identity?.organizationId,
  );

  return (
    <>
      {!hideAccountInfo && (
        <Menu shadow="sm" position="bottom-end">
          <Menu.Target>
            <Button
              color={variant === "sidebar" ? "dark" : undefined}
              size={variant === "sidebar" ? "sm" : undefined}
              variant="subtle"
              leftSection={
                <ThemeIcon
                  variant="transparent"
                  color={variant === "sidebar" ? "text" : undefined}
                  size={"sm"}
                >
                  <IconUser />
                </ThemeIcon>
              }
              classNames={{
                root: classes.accountButton,
                section: classes.accountButtonSection,
                label: classes.accountButtonLabel,
              }}
            >
              Account
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {!hideOrganizations && (
              <>
                <Menu.Label tt="uppercase" fw="bold">
                  {organizations.length === 1
                    ? "Connected to"
                    : "Use NOAN as..."}
                </Menu.Label>
                <ScrollAreaAutosize mah={500}>
                  <Spoiler
                    hideLabel="Show less"
                    showLabel="Show more"
                    styles={{
                      control: {
                        marginLeft: 12,
                        fontSize: 12,
                      },
                    }}
                    maxHeight={200}
                  >
                    {!!loggedInOrganization && (
                      <Menu.Item
                        disabled
                        rightSection={
                          <Badge size="xs" variant="light">
                            Logged in
                          </Badge>
                        }
                      >
                        <Tooltip label={loggedInOrganization.name}>
                          <Text truncate="end" w={150} size="sm">
                            {loggedInOrganization.name}
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {otherOrganizations.map((o) => (
                      <Menu.Item
                        key={o.id}
                        disabled={isLoadingSwitchOrganization}
                        onClick={() => {
                          onSwitchOrganization(o.id);
                        }}
                      >
                        <Tooltip label={o.name}>
                          <Text truncate="end" w="175" size="sm">
                            {o.name}
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    ))}
                  </Spoiler>
                </ScrollAreaAutosize>

                <Divider my={4} />
              </>
            )}

            {!isClient && !isSubscribePage(location.pathname) && (
              <Menu.Item
                component={Link}
                leftSection={
                  <ThemeIcon variant="transparent" color="dark" size="xs">
                    <IconSettings />
                  </ThemeIcon>
                }
                to="/account"
              >
                Settings
              </Menu.Item>
            )}

            <Menu.Item
              onClick={() =>
                setColorScheme(
                  computedColorScheme === "light" ? "dark" : "light",
                )
              }
              leftSection={
                <ThemeIcon variant="transparent" color="dark" size="xs">
                  {computedColorScheme === "light" ? <IconMoon /> : <IconSun />}
                </ThemeIcon>
              }
            >
              {computedColorScheme === "light" ? "Dark" : "Light"} mode
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                window.tidioChatApi.display(true);
                window.tidioChatApi.open();
              }}
              leftSection={
                <ThemeIcon variant="transparent" color="dark" size="xs">
                  <IconHelp />
                </ThemeIcon>
              }
            >
              Livechat support
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              color="red.4"
              leftSection={
                <ThemeIcon variant="transparent" size="xs" color="red.4">
                  <IconLogout />
                </ThemeIcon>
              }
              onClick={onSignOut}
            >
              Sign Out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
      {hideAccountInfo && user != null && (
        <Button
          variant="subtle"
          leftSection={<IconLogout size={18} />}
          onClick={onSignOut}
        >
          Sign Out
        </Button>
      )}
    </>
  );
}

function PreviewBanner() {
  return (
    <Container bg={"var(--mantine-primary-color-4)"} fluid>
      <Group py={6} align="baseline">
        <Text size="sm" fw="bold" c="white">
          Special Deal
        </Text>
        <Text size="sm" c="white">
          Access NOAN's full functionality -{" "}
          <Text span size="md" fw="bold">
            $10
          </Text>{" "}
          for your first month with the code{" "}
          <Code bg={"var(--mantine-primary-color-2)"} c="yellow.7">
            MONTH10
          </Code>
          .
        </Text>
      </Group>
    </Container>
  );
}

function OrganizationSwitcherItem({
  active = false,
  name,
  orgLogo,
  onSelectOrganization,
  rightSection,
}: {
  organizationId?: string;
  active?: boolean;
  name?: string;
  orgLogo?: string;
  onSelectOrganization?: () => void;
  rightSection?: JSX.Element;
}) {
  const showLogo = !!orgLogo;

  return (
    <Group
      flex={1}
      gap="xs"
      onClick={onSelectOrganization}
      w="100%"
      className={clsx(active && classes.sidebarOrgSwitcherItemActive)}
    >
      {/* Set the icon color based on org name initials, if looking at the list of orgs */}

      <Avatar
        variant={active ? "filled" : "light"}
        color={active ? "var(--mantine-primary-color-3)" : "dimmed"}
        name={name}
        src={showLogo ? orgLogo : undefined}
        radius={showLogo ? "sm" : "xl"}
        classNames={{
          image: classes.sidebarOrgSwitcherLogo,
        }}
      >
        <IconUsersGroup />
      </Avatar>

      <Group className={classes.sidebarOrgSwitcherItemLabelAction} flex={1}>
        <Box size="md" flex={rightSection ? 1 : undefined} pl="xs">
          {name ? (
            <Text {...(!active ? { truncate: true, maw: 150 } : {})}>
              {name}
            </Text>
          ) : (
            <Skeleton w={120} h={25} animate />
          )}
        </Box>
        {rightSection}
      </Group>
    </Group>
  );
}

function ThemedAuthedLayout({ children }: React.PropsWithChildren) {
  const { organization } = useOrganization();
  const { isClient } = useClientMode();
  const { updateColors, resetTheme } = useTheme();
  const { pathname } = useLocation();

  /**
   * Sets the app colors for client mode.
   */
  useEffect(() => {
    const orgTheme = organization?.settings.clientMode;

    if (
      // Some pages are accessible by owner as a preview of client mode,
      // e.g onboarding form, so we also want to apply the client mode branding
      // settings for those pages.
      (isClient || pathname === "/client/welcome") &&
      orgTheme?.colorPrimary &&
      orgTheme?.colorSecondary
    ) {
      updateColors([
        {
          colorHex: orgTheme?.colorPrimary,
          colorName: "clientModePrimaryColor",
          generateColorPalette: true,
          overrideColorName: "primaryColor",
        },
        {
          colorHex: orgTheme?.colorSecondary,
          colorName: "clientModeSecondaryColor",
          generateColorPalette: true,
          overrideColorName: "secondaryColor",
        },
      ]);
    } else {
      resetTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient, organization?.settings.clientMode, pathname]);

  return children;
}

export function AuthedLayout({ variant }: { variant?: LayoutVariant }) {
  const posthog = usePostHog();
  const {
    user,
    signOut,
    isLoadingUser,
    authError,
    jwtBearerToken,
    mustSetPassword,
    hasThirdPartyProvider,
    mustCompletePostSignup,
  } = useOptionalAuthContext();
  const { pathname } = useLocation();
  const {
    identity,
    isActive,
    isCustomer,
    isPaying,
    isLoading: isLoadingIdentity,
    hasInsightsAccess,
    isError: identityError,
    mustCompleteOnboarding,
    hasReferral,
  } = useIdentity();
  const { updateReferral } = useUpdateReferral();
  const showPreviewBanner = !isLoadingIdentity && !isActive;
  const { track } = useTrackEvent();
  const [isFirstSessionLogin, setIsFirstSessionLogin] = useLocalStorage({
    key: "noan-first-session-login",
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    /**
     * We're not tracking the event in the supabase.auth.onAuthStateChange in supabase.ts file directly mainly because
     * there are a couple of not initialized yet: react-query client, ...
     * The supabase SIGNED_IN event can be triggered multiple times within the same session (e.g. when refreshing a page).
     * To make sure we only send one logged_in user event per session, we store the very first time the event is being fired.
     */
    if (isFirstSessionLogin && user != null) {
      setIsFirstSessionLogin(false);
      track(UserEvent.LoggedIn);
    }
  }, [isFirstSessionLogin, setIsFirstSessionLogin, track, user]);

  /**
   * Once we have user information, update third-party tools:
   */
  useEffect(() => {
    if (isDeployed && user != null) {
      try {
        if (window.tidioChatApi) {
          window.tidioChatApi.setVisitorData({
            distinct_id: user.id,
            email: user.email,
          });
        }

        posthog.identify(`${user.email}#${user.id.slice(0, 6)}`, {
          ...getUserProperties(user),
        });

        // Save the Rewardful referral id to the account to add it later in the
        // Stripe customer metadata to sync the leads and conversions after checkout.
        if (window.Rewardful?.referral && !isLoadingIdentity && !hasReferral) {
          updateReferral({
            referral: window.Rewardful.referral,
          });
        }
      } catch (err) {
        console.warn("Error while identifying user");
        console.error(err);
      }
    }
  }, [hasReferral, isLoadingIdentity, posthog, updateReferral, user]);

  if (identityError) {
    signOut();
    return null;
  }

  if (mustCompletePostSignup && pathname !== "/join") {
    // User must complete post-signup tasks
    return <Navigate to="/join" />;
  } else if (authError != null) {
    const errorType =
      isNoanError(authError) && authError.errorType === NoanError.NotLoggedIn
        ? "no-auth"
        : "auth-error";

    // Unable to fetch auth for some reason - might be missing user.
    return <Navigate to={`/login?reason=${errorType}`} />;
  } else if (isLoadingUser || isLoadingIdentity) {
    // Is loading user or identity data, and there's no cached identity data available
    return <FullPageLoader />;
  } else if (showPreviewBanner && !isSubscribePage(pathname)) {
    return <Navigate to={"/subscribe"} />;
  } else if (isActive && mustCompleteOnboarding && pathname !== "/welcome") {
    return <Navigate to={"/welcome"} />;
  } else if (
    (user == null && !isLoadingUser) ||
    (identity == null && !isLoadingIdentity)
  ) {
    return <Navigate to="/login?reason=no-auth" />;
  }

  return (
    <AuthContext.Provider
      value={{
        jwtBearerToken,
        user: user!,
        identity: identity!,
        isAccountActive: isActive,
        isCustomer,
        isPayingCustomer: isPaying,
        isLoadingIdentity,
        isLoadingUser,
        authError,
        mustSetPassword,
        hasThirdPartyProvider,
        mustCompletePostSignup,
        mustCompleteOnboarding,
        signOut,
        hasInsightsAccess,
      }}
    >
      <ThemedAuthedLayout>
        <Layout
          variant={variant}
          topHeader={showPreviewBanner ? <PreviewBanner /> : undefined}
        />
      </ThemedAuthedLayout>
    </AuthContext.Provider>
  );
}

interface LayoutProps {
  variant?: LayoutVariant;
  topHeader?: JSX.Element;
  withPadding?: boolean;
}

function SidebarLayout({ topHeader }: Pick<LayoutProps, "topHeader">) {
  const navigate = useNavigate();
  const location = useLocation();

  const [
    onboardingVideoOpen,
    { open: openOnboardingVideo, close: closeOnboardingVideo },
  ] = useDisclosure();

  /**
   * Collapse/hide navigation + mobile behavior
   */
  const [navOpened, { toggle: toggleNav, close: closeNav }] = useDisclosure();
  const [navCollapsed, setNavCollapsed] = useLocalStorage({
    key: "noan-navbar-collapsed",
    defaultValue: false,
  });

  const { isClient, isCreateModeEnabled } = useClientMode();
  /**
   * Account information + org switcher
   */
  const { identity } = useAuthContext();
  const { data: organizations, isLoading: isLoadingOrgs } = useOrganizations();
  const { switchOrganization, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });

  const activeOrganization = organizations.find(
    (o) => o.id === identity.organizationId,
  );
  const availableOrganizations = organizations.filter(
    (o) => o.id !== identity.organizationId,
  );

  const hasMultipleOrganizations = availableOrganizations.length > 0;

  const orgLogo = activeOrganization?.clientLogoUrl;

  const theme = useMantineTheme();
  const isMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const isWorkspaceEnabled = useIsFeatureEnabled(FeatureFlag.UseWorkspace);

  const showSidebarMenuLinks = !isSubscribePage(location.pathname);
  const onboardingCalloutPosition = isMobileView ? "bottom" : "right";
  const onboardingCalloutIsHidden = isMobileView && !navOpened;

  const [viewedOnboardingVideo, setViewedOnboardingVideo] =
    useLocalStorage<boolean>({
      key: "noan-viewed-onboarding-video",
      getInitialValueInEffect: true,
      defaultValue: false,
    });

  const pulseOnboardingVideo = useMemo(
    () =>
      !viewedOnboardingVideo &&
      dayjs(identity.createdAt).isAfter(dayjs().subtract(3, "weeks")),
    [identity, viewedOnboardingVideo],
  );

  /**
   * Ensure the mobile nav gets toggled off when navigating elsewhere. This is important
   * if we're on mobile, otherwise the nav is over the content and you can't tell
   * we've navigated elsewhere
   */
  useEffect(() => {
    closeNav();
  }, [location, closeNav]);

  return (
    <ModalsProvider
      modalProps={{
        withinPortal: true,
      }}
    >
      <ReaderPanelContextProvider>
        <AppShell
          layout="default"
          header={{ height: { base: 60, sm: 0 } }}
          navbar={{
            width: { base: navCollapsed ? 100 : 256 },
            breakpoint: "sm",
            collapsed: { mobile: !navOpened },
          }}
          padding={{
            base: 0,
          }}
          classNames={{
            root: classes.appShell,
            main: classes.appMain,
          }}
        >
          <AppShell.Navbar
            className={classes.sidebarNavbar}
            zIndex={navOpened ? ZedIndex.Sidebar : undefined}
          >
            {/* Desktop Logo */}
            <AppShell.Section>
              <Group className={classes.sidebarExpandButtonContainer}>
                <ActionIcon
                  variant="default"
                  color="dark"
                  onClick={() => setNavCollapsed(!navCollapsed)}
                  size="lg"
                  className={classes.sidebarExpandButton}
                >
                  {navCollapsed ? (
                    <IconLayoutSidebarLeftExpand />
                  ) : (
                    <IconLayoutSidebarLeftCollapse />
                  )}
                </ActionIcon>
              </Group>

              <Stack
                align="center"
                gap="md"
                justify="space-between"
                py="lg"
                px="sm"
                className={classes.sidebarLayoutLogo}
                visibleFrom="sm"
              >
                {isLoadingOrgs ? (
                  <Skeleton w={80} h={80} />
                ) : isClient && orgLogo ? (
                  <Image
                    src={orgLogo}
                    mah={80}
                    w="fit-content"
                    maw="100%"
                    fit="contain"
                    radius="sm"
                  />
                ) : (
                  <NoanLogo />
                )}
              </Stack>
            </AppShell.Section>

            <AppShell.Section grow component={ScrollArea}>
              <Menu
                position="bottom-start"
                offset={3}
                width={380}
                disabled={
                  !hasMultipleOrganizations || isLoadingSwitchOrganization
                }
                withArrow
                arrowPosition="side"
                arrowOffset={30}
                arrowSize={10}
              >
                <Menu.Target>
                  <Group
                    w="100%"
                    px="sm"
                    py="xs"
                    align="center"
                    component={"button"}
                    justify="flex-start"
                    className={clsx([
                      classes.sidebarOrgSwitcherButton,
                      hasMultipleOrganizations &&
                        classes.sidebarOrgSwitcherButtonEnabled,
                    ])}
                  >
                    <OrganizationSwitcherItem
                      active
                      organizationId={activeOrganization?.id}
                      name={activeOrganization?.name}
                      rightSection={
                        hasMultipleOrganizations ? (
                          <ThemeIcon variant="transparent" color="dimmed">
                            {isLoadingSwitchOrganization ? (
                              <Loader size="sm" color="dimmed" />
                            ) : (
                              <IconChevronRight />
                            )}
                          </ThemeIcon>
                        ) : undefined
                      }
                    />
                  </Group>
                </Menu.Target>

                <Menu.Dropdown>
                  <ScrollAreaAutosize
                    mah={200}
                    type="hover"
                    scrollbars="y"
                    scrollbarSize={4}
                    offsetScrollbars
                  >
                    {availableOrganizations.map((org) => (
                      <Menu.Item key={org.id}>
                        <OrganizationSwitcherItem
                          name={org.name}
                          orgLogo={
                            org?.identities.find(
                              (i) => i.email === identity.email,
                            )?.primaryRole === IdentityRole.Client
                              ? org?.clientLogoUrl
                              : undefined
                          }
                          organizationId={org.id}
                          onSelectOrganization={() => {
                            switchOrganization({
                              id: org.id,
                            });
                          }}
                          rightSection={
                            <Text size="xs" c="dimmed">
                              Switch project
                            </Text>
                          }
                        />
                      </Menu.Item>
                    ))}
                  </ScrollAreaAutosize>
                </Menu.Dropdown>
              </Menu>

              {showSidebarMenuLinks && (
                <Stack mt="lg" flex={1} gap={5} w="100%">
                  <OnboardingCallout
                    calloutId={OnboardingCalloutKey.Dashboard}
                    position={onboardingCalloutPosition}
                    forceHidden={onboardingCalloutIsHidden}
                  >
                    <NavLink to="/home" icon={<IconHome />} label="Dashboard" />
                  </OnboardingCallout>
                  {isWorkspaceEnabled && !isClient && (
                    <NavLink
                      to="/workspace"
                      icon={<IconAssembly />}
                      label="Assistant"
                    />
                  )}
                  {!isClient && (
                    <OnboardingCallout
                      calloutId={OnboardingCalloutKey.Build}
                      position={onboardingCalloutPosition}
                      forceHidden={onboardingCalloutIsHidden}
                    >
                      <NavLink
                        to="/build"
                        icon={<IconStack2 />}
                        label="Knowledge"
                      />
                    </OnboardingCallout>
                  )}
                  {isCreateModeEnabled && (
                    <OnboardingCallout
                      calloutId={OnboardingCalloutKey.Create}
                      position={onboardingCalloutPosition}
                      forceHidden={onboardingCalloutIsHidden}
                    >
                      <NavLink
                        to="/create"
                        icon={<IconPencil />}
                        label="Create"
                      />
                    </OnboardingCallout>
                  )}
                  {!isClient && (
                    <OnboardingCallout
                      calloutId={OnboardingCalloutKey.Ideas}
                      position={onboardingCalloutPosition}
                      forceHidden={onboardingCalloutIsHidden}
                    >
                      <NavLink to="/ideas" icon={<IconBulb />} label="Ideas" />
                    </OnboardingCallout>
                  )}
                </Stack>
              )}
            </AppShell.Section>

            <Group
              align="center"
              justify="space-between"
              className={classes.accountButtonContainer}
            >
              {!isClient && (
                <Tooltip label="Watch a short onboarding video">
                  <ActionIcon
                    onClick={() => {
                      setViewedOnboardingVideo(true);
                      openOnboardingVideo();
                    }}
                    variant={pulseOnboardingVideo ? "outline" : "subtle"}
                    size="lg"
                    color={pulseOnboardingVideo ? undefined : "dark"}
                    className={
                      (pulseOnboardingVideo && "animate-firstuse") || undefined
                    }
                  >
                    <IconSchool />
                  </ActionIcon>
                </Tooltip>
              )}
              <Group flex={1} justify="flex-end">
                <UserMenu
                  hideAccountInfo={false}
                  hideOrganizations
                  variant="sidebar"
                />
              </Group>
            </Group>
            <FirstTimeVideoModal
              opened={onboardingVideoOpen}
              onClose={() => closeOnboardingVideo()}
            />
          </AppShell.Navbar>
          <AppShell.Header hiddenFrom="sm">
            {/* Mobile Header + Logo */}
            <Group w="100%" h="100%" px="xl" justify="space-between">
              {isLoadingOrgs ? (
                <Skeleton w={35} h={35} />
              ) : isClient && orgLogo ? (
                <Image src={orgLogo} mah={35} fit="contain" radius="sm" />
              ) : (
                <NoanLogo width={90} />
              )}
              <Burger opened={navOpened} onClick={toggleNav} />
            </Group>
          </AppShell.Header>
          <AppShell.Main>
            {topHeader}
            <Box className={classes.outletContainer}>
              <Outlet />
            </Box>
          </AppShell.Main>
        </AppShell>
      </ReaderPanelContextProvider>
    </ModalsProvider>
  );
}

function DefaultLayout({ topHeader, withPadding }: LayoutProps) {
  const shellPadding = { base: "sm", md: "xl" };
  const mainPadding = {
    base: topHeader ? topHeaderHeights.mobile : 0,
    xs: topHeader ? topHeaderHeights.desktop : 0,
  };

  return (
    <ReaderPanelContextProvider>
      <AppShell
        p={withPadding ? shellPadding : undefined}
        bg="var(--mantine-color-body)"
      >
        <AppShell.Header zIndex={ZedIndex.Header} withBorder={false}>
          {topHeader}
        </AppShell.Header>

        <AppShell.Main pt={mainPadding}>
          <Outlet />
          <ScrollRestoration getKey={(location) => location.pathname} />
        </AppShell.Main>
      </AppShell>
    </ReaderPanelContextProvider>
  );
}

export function MinimalLayout() {
  return (
    <div className={classes.minimalRoot}>
      <Outlet />
    </div>
  );
}

export function Layout({ variant, topHeader, withPadding }: LayoutProps) {
  /**
   * Redirect beta.getnoan.com to app.getnoan.com in all cases, preserving
   * path information.
   */
  useEffect(() => {
    const { host, pathname, search } = document.location;

    if (host === "beta.getnoan.com") {
      const newLocation = new URL(`https://app.getnoan.com${pathname}`);
      newLocation.search = search;
      // document.location.assign(newLocation);
    }
  }, []);

  if (variant === "sidebar") {
    return <SidebarLayout topHeader={topHeader} />;
  } else if (variant === "minimal") {
    return <MinimalLayout />;
  } else {
    return (
      <DefaultLayout
        withPadding={withPadding}
        variant={variant}
        topHeader={topHeader}
      />
    );
  }
}
