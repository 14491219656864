import type { FeatureId, FeatureSettingFilters, FeatureSettingGet } from "core";
import { trpc } from "./trpc";

export function useFeatureSettings(input: FeatureSettingFilters) {
  const { data, isLoading } = trpc.featureSetting.getAll.useQuery(input);

  return {
    settings: data,
    isLoadingSettings: isLoading,
  };
}

export function isFeatureSettingEnabled(
  featureId: FeatureId,
  settings?: { featureId: string; enabled: boolean }[],
) {
  return !!settings?.find((setting) => setting.featureId === featureId)
    ?.enabled;
}

export function useFeatureSetting(input: FeatureSettingGet) {
  const { data, isLoading, ...query } = trpc.featureSetting.get.useQuery(input);

  return {
    feature: data,
    isFeatureLoading: isLoading,
    ...query,
  };
}

export function useCreateFeatureSetting() {
  const utils = trpc.useUtils();

  const { mutate, isLoading, ...mutation } =
    trpc.featureSetting.create.useMutation({
      onSettled() {
        utils.featureSetting.get.invalidate();
        utils.featureSetting.getAll.invalidate();
      },
    });

  return {
    ...mutation,
    createFeatureSetting: mutate,
    isCreatingFeatureSetting: isLoading,
  };
}

export function useUpdateFeatureSetting() {
  const utils = trpc.useUtils();

  const { mutate, isLoading, ...mutation } =
    trpc.featureSetting.update.useMutation({
      onSettled() {
        utils.featureSetting.get.invalidate();
        utils.featureSetting.getAll.invalidate();
      },
    });

  return {
    ...mutation,
    updateFeatureSetting: mutate,
    isUpdatingFeatureSetting: isLoading,
  };
}
