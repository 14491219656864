import { ConvoBucket, fixedWidgets, IdentityRole, type Fact } from "core";
import { useMemo } from "react";
import { useIsRole } from "../../auth/useIdentity";
import { trpc } from "../../utils/trpc";
import { useFacts } from "../../utils/useFacts";
import {
  groupModulesByStack,
  useFilteredStacksWithOnlyReadableBlocks,
} from "../../utils/useIsStackAccessible";
import {
  useOrganization,
  useOrganizationStacks,
} from "../../utils/useOrganization";
import { BlocksGroupedByStack, getBlockPath } from "../../utils/useStacks";
import { Widget, widgetContent } from "./widgets/config";

export function getInitials(name?: string) {
  if (!name) return null;

  const splitNames = name.split(" ");

  return splitNames.map((name) => name.substring(0, 1)).join("");
}

function sortByLastActivityDate(activities: Activity[]) {
  return activities.sort((a, b) => {
    const firstDate = new Date(a.updatedAt ?? a.createdAt);
    const secondDate = new Date(b.updatedAt ?? b.createdAt);
    if (firstDate < secondDate) return 1;
    else if (firstDate > secondDate) return -1;
    else return 0;
  });
}

export enum ActivityType {
  Asset = "asset",
  Block = "block",
  Idea = "idea",
  Task = "task",
}

type Activity = {
  type: ActivityType;
  id: string;
  redirectTo?: string;
  title: string;
  updatorInitial: string | null;
  updatorName?: string;
  createdAt: Date;
  updatedAt?: Date | null;
  headlinePrefix?: string;
};

type Dashboard = {
  isLoadingDashboard: boolean;
  widgets: Widget[];
  stats: {
    totalCompletedStacks: number;
    totalCompletedTasks: number;
    totalBlocksCompleted: number;
    totalAssets: number;
    totalTeamMembersInvited: number;

    topBuckets: {
      bucket: ConvoBucket;
      count: number;
    }[];
    recentActivities: Activity[];
  };
  orgStacks: BlocksGroupedByStack[];
  facts: Fact[];
};

export function useDashboard(): Dashboard {
  const { data: orgStats, isLoading } = trpc.organization.getStats.useQuery();

  const { organization } = useOrganization();
  const organizationWidgets = [
    ...(organization?.settings.widgets ?? []),
    ...fixedWidgets,
  ];
  const isClient = useIsRole(IdentityRole.Client);
  const { stacks: allStacks } = useFilteredStacksWithOnlyReadableBlocks();
  const [facts] = useFacts();
  const { stacks: organizationStacks } = useOrganizationStacks();

  const allStacksGrouped = useMemo<BlocksGroupedByStack[]>(
    () => groupModulesByStack(allStacks || [], facts),
    [facts, allStacks],
  );
  const orgStacks = useMemo(
    () =>
      allStacksGrouped.filter((st) =>
        organizationStacks.includes(st.stack.slug),
      ),
    [allStacksGrouped, organizationStacks],
  );

  const totalCompletedStacks = useMemo(
    () =>
      orgStacks.reduce((prev, cur) => {
        const isStackComplete =
          cur.blocks.filter((mod) =>
            facts.find((f) => f.blockPath === mod.knowledgeslug),
          ).length === cur.blocks.length;

        return isStackComplete ? prev + 1 : prev;
      }, 0),
    [facts, orgStacks],
  );

  function formatActivities() {
    const recentAssets = orgStats?.recentAssets
      ? orgStats.recentAssets.map((asset) => {
          const lastEntry = asset.entries.at(0);
          const name =
            lastEntry?.creator?.name ?? lastEntry?.creator?.email ?? "";

          return {
            type: ActivityType.Asset,
            id: asset.id,
            redirectTo: `/create/c/${asset.id}`,
            title: asset.title ?? "Untitled",
            updatorInitial: getInitials(name.toUpperCase()),
            updatorName: name,
            createdAt: asset.createdAt,
            updatedAt: lastEntry?.createdAt,
          };
        })
      : [];

    const recentFacts =
      !isClient && orgStats?.recentFacts
        ? orgStats.recentFacts
            .filter((fact) =>
              allStacksGrouped
                .flatMap((st) => st.blocks)
                .map((blo) => blo.knowledgeslug!)
                .includes(fact.blockPath),
            )
            .map((fact) => {
              const updatorName = fact.creator.name ?? fact.creator.email ?? "";
              const blockPath = fact?.blockPath
                ? getBlockPath({
                    blockPath: fact.blockPath,
                    stacks: orgStacks,
                  })
                : undefined;
              return {
                type: ActivityType.Block,
                id: fact.id,
                redirectTo: `/build/b/${fact.blockPath}`,
                title: blockPath?.block?.title ?? "Untitled",
                updatorInitial: getInitials(updatorName.toUpperCase()),
                updatorName: updatorName,
                createdAt: fact.createdAt,
                updatedAt: fact.parentFactId ? fact.createdAt : null,
              };
            })
        : [];

    const recentIdeas =
      !isClient && orgStats?.recentIdeas
        ? orgStats.recentIdeas.map((idea) => {
            const updatorName = idea.creator.name ?? idea.creator.email ?? "";
            const blockPath = idea?.content.provenance?.blockPath
              ? getBlockPath({
                  blockPath: idea.content.provenance.blockPath,
                  stacks: orgStacks,
                })
              : undefined;
            return {
              type: ActivityType.Idea,
              id: idea.id,
              redirectTo: blockPath?.block?.knowledgeslug
                ? `/build/b/${blockPath.block.knowledgeslug}`
                : `/ideas`,
              title: idea.content.title ?? "Untitled",
              updatorInitial: getInitials(updatorName.toUpperCase()),
              updatorName,
              createdAt: idea.createdAt,
              updatedAt: idea.updatedAt,
            };
          })
        : [];

    const recentTasks = orgStats?.recentTasks
      ? orgStats.recentTasks.map((task) => {
          const updatorName = task.creator.name ?? task.creator.email ?? "";

          return {
            type: ActivityType.Task,
            id: task.id,
            title: task.title ?? "Untitled",
            updatorInitial: getInitials(updatorName.toUpperCase()),
            updatorName,
            createdAt: task.createdAt,
            updatedAt: task.updatedAt,
            headlinePrefix: task.completed
              ? "Completed"
              : task.createdAt.getTime() === task.updatedAt?.getTime()
                ? "Created"
                : "Updated",
          };
        })
      : [];

    return sortByLastActivityDate([
      ...recentAssets,
      ...recentFacts,
      ...recentIdeas,
      ...recentTasks,
    ]);
  }

  const stats = {
    totalCompletedStacks,
    totalCompletedTasks: orgStats?.totalTasksCompleted ?? 0,
    totalBlocksCompleted: orgStats?.totalBlocksCompleted ?? 0,
    totalAssets: orgStats?.totalAssets ?? 0,
    totalTeamMembersInvited: (organization?.identities.length ?? 0) - 1, // -1 to exclude ourself
    topBuckets: orgStats?.topBuckets.slice(0, 5) ?? [],
    recentActivities: formatActivities(),
  } satisfies Dashboard["stats"];

  return {
    isLoadingDashboard: isLoading,
    widgets: organizationWidgets.map((widget) => ({
      id: widget,
      type: fixedWidgets.includes(widget) ? "fixed" : "flexible",
      content: widgetContent[widget],
    })),
    stats,
    orgStacks,
    facts,
  };
}
