import { IdentityRole } from "core";
import { useEffect } from "react";
import { setSentryUser } from "../sentry";
import { trpc } from "../utils/trpc";
import { useAuthContext, useOptionalAuthContext } from "./useAuthContext";

export function useIdentity() {
  const { user } = useOptionalAuthContext();
  const { data, isLoading, isError } = trpc.identity.get.useQuery(undefined, {
    enabled: user != null,
    retry: 5,
    cacheTime: Infinity,
    meta: {
      persist: false,
    },
  });

  useEffect(() => {
    if (!data?.identity) {
      return;
    }

    setSentryUser(data.identity);
  }, [data?.identity]);

  return {
    isError,
    isLoading: !isError && isLoading && user != null,
    /**
     * While loading, we assume the user has a valid account.
     * This will hold true until the identity data finishes loading.
     */
    isActive:
      typeof data?.isAccountActive === "undefined"
        ? true
        : data.isAccountActive,
    isCustomer: data?.isCustomer ?? false,
    isPaying: data?.isPayingCustomer ?? false,
    hasInsightsAccess: data?.hasInsightsAccess ?? false,
    mustCompleteOnboarding: data?.mustCompleteOnboarding ?? false,
    hasReferral: data?.hasReferral ?? false,
    identity: data?.identity,
  };
}

export function useSearchIdentities({ q }: { q?: string }) {
  const { data, ...rest } = trpc.identity.search.useQuery(
    { q },
    {
      enabled: !!q && q.length >= 3,
      meta: {
        persist: true,
      },
    },
  );

  return {
    data: data ?? [],
    ...rest,
  };
}

export function useIsRole(role: IdentityRole) {
  const { identity } = useAuthContext();

  return identity.primaryRole === role;
}
