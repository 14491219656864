import classes from "./StackIcons.module.css";

export function SponsorshipsIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.47 45.1871L113.047 60.6858L127.348 49.3973L131.046 67.2368L148.345 61.5203L145.719 79.549L163.93 80.0936L155.296 96.1368L172.223 102.877L158.623 115L172.223 127.123L155.296 133.863L163.93 149.906L145.719 150.451L148.345 168.48L131.046 162.763L127.348 180.603L113.047 169.314L103.47 184.813L93.894 169.314L79.5933 180.603L75.895 162.763L58.5957 168.48L61.2217 150.451L43.0107 149.906L51.6445 133.863L34.7183 127.123L48.3184 115L34.7183 102.877L51.6445 96.1368L43.0107 80.0936L61.2217 79.549L58.5957 61.5203L75.895 67.2368L79.5933 49.3973L93.894 60.6858L103.47 45.1871ZM111.992 70.7168V90.3966L94.9487 100.238L120.516 115C125.789 118.045 129.038 123.672 129.038 129.761C129.038 135.851 125.789 141.478 120.515 144.522L94.9487 159.283V139.602L111.993 129.76L86.4264 115C81.1526 111.955 77.9038 106.328 77.9038 100.239C77.9038 94.1493 81.1524 88.5224 86.4259 85.4777L111.992 70.7168Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.508 69.8125L205.507 154.363C205.507 166.542 199.009 177.795 188.462 183.885L115.239 226.16C107.813 230.448 98.6646 230.448 91.2388 226.16L12.9707 180.972C5.54492 176.685 0.970703 168.762 0.970703 160.188V75.6366C0.970703 63.4576 7.46826 52.2039 18.0156 46.1144L91.2397 3.8396C98.665 -0.44751 107.814 -0.44751 115.239 3.8396L193.508 49.0278C200.933 53.3151 205.508 61.2382 205.508 69.8125ZM22.0156 63.4865L99.2393 18.9023C101.715 17.4733 104.764 17.4733 107.239 18.9023L184.463 63.4873C186.938 64.9164 188.463 67.5575 188.463 70.4156L188.462 159.585C188.462 162.443 186.937 165.084 184.462 166.513L107.239 211.098C104.764 212.527 101.714 212.527 99.2388 211.098L22.0156 166.513C19.54 165.084 18.0156 162.443 18.0156 159.585V70.4148C18.0156 67.5565 19.54 64.9155 22.0156 63.4865Z"
        fill="currentColor"
      />
    </svg>
  );
}
