import { z } from "zod";

export const ACTIVE_CAMPAIGN_ONBOARDING_LIST_ID = 21;

export enum ActiveCampaignStatus {
  Active = 1,
  Unconfirmed = 0,
  Unsubscribed = 2,
  Bounced = 3,
}

export enum ActiveCampaignFieldIds {
  SubscriptionType = "9",
  JobTitle = "5",
  ProjectName = "4",
  BusinessIndustry = "11",
  UsingClientMode = "10",
}

const ActiveCampaignFieldValueSchema = z.object({
  field: z.string(),
  value: z.string(),
});

export type ActiveCampaignFieldValue = z.infer<
  typeof ActiveCampaignFieldValueSchema
>;

const ActiveCampaignContactSchema = z.object({
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.number().optional(),
  fieldValues: z.array(ActiveCampaignFieldValueSchema).optional(),
});

export type ActiveCampaignContact = z.infer<typeof ActiveCampaignContactSchema>;

const ActiveCampaignCreateContactResponseSchema = z.object({
  contact: z.object({
    id: z.string(),
    email: z.string().email(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    phone: z.string().optional(),
  }),
});

export type ActiveCampaignCreateContactResponse = z.infer<
  typeof ActiveCampaignCreateContactResponseSchema
>;

const ActiveCampaignAddContactToListSchema = z.object({
  contact: z.number(),
  list: z.number(),
  status: z.number(),
});

export type ActiveCampaignAddContactToList = z.infer<
  typeof ActiveCampaignAddContactToListSchema
>;

const ActiveCampaignAddContactToListResponseSchema = z.object({
  contactList: z.array(
    z.object({
      id: z.string(),
      contact: z.number(),
      list: z.number(),
      status: z.number(),
    }),
  ),
  contacts: z.array(z.object({})),
});

export type ActiveCampaignAddContactToListResponse = z.infer<
  typeof ActiveCampaignAddContactToListResponseSchema
>;

const ActiveCampaignSearchContactsResponseSchema = z.object({
  contacts: z.array(
    ActiveCampaignContactSchema.merge(z.object({ id: z.string() })),
  ),
});

export type ActiveCampaignSearchContactsResponse = z.infer<
  typeof ActiveCampaignSearchContactsResponseSchema
>;
