import { Badge, Button, Group, Text, Title, Tooltip } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { WidgetId } from "core";
import { useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useIsGranted } from "../../../auth/useIsGranted";
import { useClientMode } from "../../../utils/useClientMode";
import { useDashboard } from "../useDashboard";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import { widgetProps } from "./config";

export function ClientOnboardingStatusDetails({
  isAllCompleted,
  isNoneCompleted,
  completed,
  total,
}: {
  isAllCompleted: boolean;
  isNoneCompleted: boolean;
  completed: number;
  total: number;
}) {
  const completionProps = useMemo(() => {
    if (total === 0) {
      return {
        text: "Inactive",
        tooltip: "The onboarding has not been set up.",
        color: "gray",
        icon: undefined,
      };
    }
    if (isAllCompleted) {
      return {
        text: "Completed",
        tooltip: "All the required information has been completed.",
        color: undefined,
        icon: <IconCheck size={16} />,
      };
    }
    if (isNoneCompleted) {
      return {
        text: "Not started",
        tooltip: undefined,
        color: "base.6",
        icon: undefined,
      };
    }
    return {
      text: "In progress",
      tooltip: "Some of the information has been completed.",
      color: "yellow",
      icon: undefined,
    };
  }, [isAllCompleted, isNoneCompleted, total]);

  return (
    <Group align="center" justify="space-between">
      <Tooltip
        label={completionProps.tooltip}
        disabled={!completionProps.tooltip}
      >
        <Badge
          size="lg"
          color={completionProps.color}
          leftSection={completionProps.icon}
        >
          {completionProps.text}
        </Badge>
      </Tooltip>
      {total > 0 && (
        <Tooltip label="Blocks completed">
          <Title order={4} fw="bold">
            {completed}/{total}
          </Title>
        </Tooltip>
      )}
    </Group>
  );
}

function Content({
  isOnboardingSet,
  isAllCompleted,
  isNoneCompleted,
  nextIncompleteBlock,
  status,
}: {
  isOnboardingSet: boolean;
  isAllCompleted: boolean;
  isNoneCompleted: boolean;
  nextIncompleteBlock?: string;
  status: JSX.Element;
}) {
  const navigate = useNavigate();

  const { orgStacks } = useDashboard();

  const nextIncompleteBlockIndex = orgStacks
    .flatMap((stack) => stack.blocks)
    .findIndex((b) => b.knowledgeslug! === nextIncompleteBlock);

  function openOnboarding() {
    navigate({
      pathname: "/client/welcome",
      search: createSearchParams({
        ...(nextIncompleteBlockIndex !== -1 && {
          _step: nextIncompleteBlockIndex.toString(),
        }),
      }).toString(),
    });
  }

  return (
    <>
      <Text size="sm" c="dimmed">
        Add details about your business so we can start building together. You
        can update these at any time.
      </Text>
      {status}
      {isOnboardingSet && (
        <>
          {isAllCompleted ? (
            <>
              <Text c="dimmed">
                You've completed all the information assigned!
              </Text>
              <Button variant="outline" size="md" onClick={openOnboarding}>
                Review details
              </Button>
            </>
          ) : (
            <QuickLinkButton
              label={`${isNoneCompleted ? "Begin" : "Complete"} onboarding.`}
              onClick={openOnboarding}
            />
          )}
        </>
      )}
    </>
  );
}

export function ClientOnboardingWidget() {
  const { hasPermission } = useIsGranted({
    permission: "widget.read.client-onboarding",
  });
  const {
    isClientModeEnabled,
    allBlockPathsCompleted,
    clientBlocks,
    nextIncompleteBlock,
    isAllCompleted,
    isNoneCompleted,
  } = useClientMode();

  const clientModeStatus = useMemo(
    () => (
      <ClientOnboardingStatusDetails
        isAllCompleted={isAllCompleted}
        isNoneCompleted={isNoneCompleted}
        completed={allBlockPathsCompleted.length}
        total={clientBlocks.length}
      />
    ),
    [
      allBlockPathsCompleted.length,
      clientBlocks.length,
      isAllCompleted,
      isNoneCompleted,
    ],
  );

  if (!hasPermission || !isClientModeEnabled) return null;

  return (
    <BaseWidget title={widgetProps[WidgetId.ClientOnboarding].label}>
      <Content
        isOnboardingSet={clientBlocks.length > 0}
        isAllCompleted={isAllCompleted}
        isNoneCompleted={isNoneCompleted}
        status={clientModeStatus}
        nextIncompleteBlock={nextIncompleteBlock}
      />
    </BaseWidget>
  );
}
