import { z } from "zod";

export const previewConstraints = {
  maxCreatorGeneration: 5,
  maxAssistantGenerationPerUser: 10,
} as const;

export const ItemPriceTierSchema = z.object({
  unit_amount_decimal: z.string().nullable(),
  up_to: z.number().nullable(),
});

export const AccountContactInfoSchema = z.object({
  email: z
    .array(
      z.object({
        value: z.string().optional(),
        primary: z.boolean().optional(),
      }),
    )
    .optional(),
  rewardful: z
    .object({
      referralId: z.string(),
    })
    .optional(),
});

export type ItemPriceTier = z.infer<typeof ItemPriceTierSchema>;
export type AccountContactInfo = z.infer<typeof AccountContactInfoSchema>;
