import { Button, Group, Text } from "@mantine/core";
import {
  RichTextEditor as BaseTextEditor,
  Link,
  type RichTextEditorProps,
} from "@mantine/tiptap";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useCallback } from "react";
import { Markdown } from "tiptap-markdown";
import classes from "./RichTextEditor.module.css";

export function RichTextEditor({
  content,
  description,
  placeholder,
  onSave,
  onUpdate,
  isSaving,
  editorProps,
}: {
  content?: string;
  description?: string;
  placeholder?: string;
  onSave?: (markdownValue: string) => void;
  onUpdate?: (markdownValue: string) => void;
  isSaving?: boolean;
  editorProps?: Omit<Partial<RichTextEditorProps>, "editor" | "classNames">;
}) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Markdown,
      ...(placeholder ? [Placeholder.configure({ placeholder })] : []),
    ],
    content,
    onUpdate(props) {
      onUpdate?.(props.editor.storage.markdown.getMarkdown() as string);
    },
  });

  const onPressSaveButton = useCallback(() => {
    if (!editor) {
      return;
    }

    const markdown = editor.storage.markdown.getMarkdown() as string;

    onSave && onSave(markdown);
  }, [onSave, editor]);

  return (
    <BaseTextEditor
      editor={editor}
      classNames={{
        root: classes.editorRoot,
        content: classes.content,
      }}
      {...editorProps}
    >
      <BaseTextEditor.Toolbar
        sticky
        classNames={{
          toolbar: classes.toolbar,
        }}
      >
        <Group
          w="100%"
          gap={"xs"}
          preventGrowOverflow
          className={classes.toolbarInnerContainer}
        >
          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.Bold />
            <BaseTextEditor.Italic />
            <BaseTextEditor.Underline />
            <BaseTextEditor.ClearFormatting />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.H1 />
            <BaseTextEditor.H2 />
            <BaseTextEditor.H3 />
            <BaseTextEditor.H4 />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.Blockquote />
            <BaseTextEditor.BulletList />
            <BaseTextEditor.OrderedList />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.Link initialExternal />
            <BaseTextEditor.Unlink />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup flex={1}>
            <BaseTextEditor.Undo />
            <BaseTextEditor.Redo />
          </BaseTextEditor.ControlsGroup>

          {onSave && (
            <Button
              size="xs"
              color="black"
              type="submit"
              loading={isSaving}
              onClick={onPressSaveButton}
            >
              Save Changes
            </Button>
          )}
        </Group>
      </BaseTextEditor.Toolbar>

      <BaseTextEditor.Content />
      {description && (
        <Text size="xs" py="xs" pl="md" className={classes.description}>
          {description}
        </Text>
      )}
    </BaseTextEditor>
  );
}
