import classes from "./StackIcons.module.css";

export function PartnershipsIcon() {
  return (
    <svg
      width="207"
      height="230"
      viewBox="0 0 207 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M171.541 95.433L171.541 115.114L154.497 105.274L154.497 134.243C154.497 140.675 151.067 146.617 145.497 149.833L103.364 174.16L103.364 174.159L86.3188 164.318L137.452 134.796L137.452 105.275L120.407 115.116L120.407 95.4346L145.974 80.5823L171.541 95.433Z"
        fill="currentColor"
      />
      <path
        d="M35.1858 114.959L35.1863 134.641L60.7526 149.491L86.3204 134.639L86.3204 114.957L69.275 124.798L69.2746 95.2776L120.408 65.7553L103.364 55.9145L103.363 55.9137L61.23 80.2407C55.6605 83.4562 52.2296 89.3989 52.2299 95.83L52.23 124.8L35.1858 114.959Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.521 154.363L205.522 69.8125C205.522 61.2382 200.948 53.3151 193.522 49.0279L115.254 3.83957C107.828 -0.447512 98.6799 -0.4475 91.2543 3.8396L18.0301 46.1144C7.48288 52.2038 0.985372 63.4576 0.985352 75.6366L0.985399 160.188C0.98542 168.762 5.55978 176.685 12.9854 180.972L91.2534 226.16C98.679 230.448 107.828 230.448 115.253 226.16L188.477 183.885C199.024 177.795 205.521 166.542 205.521 154.363ZM99.2541 18.9023L22.0301 63.4865C19.5549 64.9155 18.03 67.5566 18.03 70.4147L18.0301 159.585C18.0301 162.443 19.5549 165.084 22.0301 166.513L99.2536 211.098C101.729 212.527 104.778 212.527 107.254 211.098L184.477 166.513C186.952 165.084 188.477 162.443 188.477 159.585L188.478 70.4156C188.478 67.5575 186.953 64.9164 184.478 63.4873L107.254 18.9023C104.779 17.4733 101.729 17.4733 99.2541 18.9023Z"
        fill="currentColor"
      />
      <path
        d="M171.541 95.433L171.541 115.114L154.497 105.274L154.497 134.243C154.497 140.675 151.067 146.617 145.497 149.833L103.364 174.16L103.364 174.159L86.3188 164.318L137.452 134.796L137.452 105.275L120.407 115.116L120.407 95.4346L145.974 80.5823L171.541 95.433Z"
        stroke="currentColor"
      />
      <path
        d="M35.1858 114.959L35.1863 134.641L60.7526 149.491L86.3204 134.639L86.3204 114.957L69.275 124.798L69.2746 95.2776L120.408 65.7553L103.364 55.9145L103.363 55.9137L61.23 80.2407C55.6605 83.4562 52.2296 89.3989 52.2299 95.83L52.23 124.8L35.1858 114.959Z"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.521 154.363L205.522 69.8125C205.522 61.2382 200.948 53.3151 193.522 49.0279L115.254 3.83957C107.828 -0.447512 98.6799 -0.4475 91.2543 3.8396L18.0301 46.1144C7.48288 52.2038 0.985372 63.4576 0.985352 75.6366L0.985399 160.188C0.98542 168.762 5.55978 176.685 12.9854 180.972L91.2534 226.16C98.679 230.448 107.828 230.448 115.253 226.16L188.477 183.885C199.024 177.795 205.521 166.542 205.521 154.363ZM99.2541 18.9023L22.0301 63.4865C19.5549 64.9155 18.03 67.5566 18.03 70.4147L18.0301 159.585C18.0301 162.443 19.5549 165.084 22.0301 166.513L99.2536 211.098C101.729 212.527 104.778 212.527 107.254 211.098L184.477 166.513C186.952 165.084 188.477 162.443 188.477 159.585L188.478 70.4156C188.478 67.5575 186.953 64.9164 184.478 63.4873L107.254 18.9023C104.779 17.4733 101.729 17.4733 99.2541 18.9023Z"
        stroke="currentColor"
      />
    </svg>
  );
}
