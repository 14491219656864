import {
  Box,
  Card,
  Loader,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEye, IconNote } from "@tabler/icons-react";
import { FeatureId, IdentityRole, WidgetId } from "core";
import { useIsGranted } from "../../../auth/useIsGranted";
import { MarkdownContent } from "../../../components/MarkdownContent";
import { QuickViewModal } from "../../../components/QuickViewModal";
import { useClientMode } from "../../../utils/useClientMode";
import { useFeatureSetting } from "../../../utils/useFeatureSettings";
import { BaseWidget } from "./BaseWidget";
import classes from "./ClientNoteWidget.module.css";
import { widgetProps } from "./config";

export function NoteContent({
  icon,
  iconTooltip,
  noContentElement,
  additionalElement,
  onClickCard,
}: {
  icon?: JSX.Element;
  iconTooltip?: string;
  noContentElement?: JSX.Element;
  additionalElement?: (props: {
    content?: string;
    hasInitialContent: boolean;
  }) => JSX.Element;
  onClickCard?: () => void;
}) {
  const [
    isQuickViewModalOpened,
    { open: openQuickViewModal, close: closeQuickViewModal },
  ] = useDisclosure();

  const { feature, isFeatureLoading, refetch, isRefetching } =
    useFeatureSetting({
      role: IdentityRole.Client,
      featureId: FeatureId.ClientNote,
    });

  const content = feature?.value?.content;

  return (
    <>
      {!content ? (
        <Stack align="center" gap="xs">
          <ThemeIcon size={50} variant="transparent">
            <IconNote size={50} stroke={1.5} />
          </ThemeIcon>
          <Text c="dimmed">No note shared yet.</Text>
          {noContentElement && <Box w="100%">{noContentElement}</Box>}
        </Stack>
      ) : (
        <Card
          className={classes.card}
          withBorder={false}
          radius={0}
          mb="md"
          mah={400}
        >
          <Tooltip withArrow label={iconTooltip ?? "View note"}>
            <ThemeIcon
              variant="transparent"
              pos="absolute"
              right={5}
              top={5}
              color="base.6"
              className={classes.icon}
              onClick={(e) => {
                e.stopPropagation();
                if (onClickCard) {
                  refetch();
                  onClickCard();
                } else {
                  openQuickViewModal();
                }
              }}
            >
              {icon ?? <IconEye />}
            </ThemeIcon>
          </Tooltip>
          <MarkdownContent content={content} preview />
          {isFeatureLoading && <Loader size={18} type="oval" />}
          <QuickViewModal
            title={"Quick Share"}
            content={content}
            isOpened={isQuickViewModalOpened}
            onClose={closeQuickViewModal}
          />
        </Card>
      )}
      {!isRefetching &&
        additionalElement &&
        additionalElement({
          content,
          hasInitialContent: !!feature,
        })}
    </>
  );
}

export function ClientNoteWidget() {
  const { hasPermission } = useIsGranted({
    permission: "widget.read.client-note",
  });
  const { isClientModeEnabled } = useClientMode();

  if (!hasPermission || !isClientModeEnabled) return null;

  return (
    <BaseWidget title={widgetProps[WidgetId.ClientNote].label}>
      <Text c="dimmed" size="sm">
        This is where your service provider shares important notes, links, and
        updates with you.
      </Text>
      <NoteContent />
    </BaseWidget>
  );
}
