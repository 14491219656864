import classes from "./StackIcons.module.css";

export function RecruitingIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M145.493 65.7554L162.537 75.5961L145.493 85.4368L145.493 105.119L179.582 124.799L162.537 134.64L94.3584 95.2775L111.403 85.4368L111.404 85.4358L128.448 95.2761L128.448 85.9893C128.448 79.5588 131.879 73.6168 137.447 70.4013L145.492 65.7564L145.493 65.7554Z"
        fill="currentColor"
      />
      <path
        d="M69.2689 75.5966L65.0083 73.1365C59.327 69.8561 52.2251 73.9562 52.2251 80.5165C52.2251 83.5609 53.8492 86.3742 56.4857 87.8965L69.2689 95.2776L82.0531 87.8969C84.6899 86.3746 86.3143 83.5612 86.3143 80.5164C86.3143 73.956 79.2124 69.8557 73.5309 73.136L69.2689 75.5966Z"
        fill="currentColor"
      />
      <path
        d="M77.3142 105.119L60.2695 114.959L85.8357 129.722L68.7926 139.562L43.2254 124.8L26.1807 134.641L94.3586 174.003L111.403 164.163L85.8369 149.402L102.88 139.562L128.448 154.322L145.493 144.481L77.3142 105.119Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.516 154.363L205.517 69.8125C205.517 61.2382 200.943 53.3151 193.517 49.0279L115.249 3.83957C107.824 -0.447512 98.675 -0.4475 91.2494 3.8396L18.0253 46.1144C7.478 52.2038 0.980489 63.4576 0.980469 75.6366L0.980516 160.188C0.980537 168.762 5.5549 176.685 12.9805 180.972L91.2485 226.16C98.6741 230.448 107.823 230.448 115.248 226.16L188.472 183.885C199.019 177.795 205.516 166.542 205.516 154.363ZM99.2493 18.9023L22.0252 63.4865C19.55 64.9155 18.0251 67.5566 18.0252 70.4147L18.0252 159.585C18.0252 162.443 19.55 165.084 22.0252 166.513L99.2487 211.098C101.724 212.527 104.774 212.527 107.249 211.098L184.472 166.513C186.947 165.084 188.472 162.443 188.472 159.585L188.473 70.4156C188.473 67.5575 186.948 64.9164 184.473 63.4873L107.249 18.9023C104.774 17.4733 101.724 17.4733 99.2493 18.9023Z"
        fill="currentColor"
      />
    </svg>
  );
}
