import { trpc } from "./trpc";

/**
 * Thin wrappers around react-query/trpc mutations for Account.
 */
export function useAccountMutation() {
  const utils = trpc.useUtils();

  const {
    mutate: updateSubscriptionItem,
    isLoading: isLoadingUpdateSubscriptionItem,
  } = trpc.account.updateSubscriptionItem.useMutation({
    onSettled() {
      utils.account.getBilling.invalidate();
    },
  });

  const { mutate: resubscribe, isLoading: isResubscribing } =
    trpc.account.resubscribe.useMutation({
      onSettled() {
        utils.account.getBilling.invalidate();
        utils.identity.get.invalidate();
      },
    });

  return {
    updateSubscriptionItem,
    isLoadingUpdateSubscriptionItem,
    resubscribe,
    isResubscribing,
  };
}

export function useUpdateReferral() {
  const utils = trpc.useUtils();

  const { mutate: updateReferral, isLoading: isUpdatingReferral } =
    trpc.account.updateReferral.useMutation({
      onSettled() {
        utils.identity.get.invalidate();
      },
    });

  return {
    updateReferral,
    isUpdatingReferral,
  };
}
