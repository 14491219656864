import {
  ActionIcon,
  Anchor,
  Card,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconEdit, IconEye, IconNote } from "@tabler/icons-react";
import { FeatureId, FeatureSettingUpsert, IdentityRole, WidgetId } from "core";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useIsGranted } from "../../../auth/useIsGranted";
import { RichTextEditor } from "../../../components/RichTextEditor";
import { useClientMode } from "../../../utils/useClientMode";
import {
  useCreateFeatureSetting,
  useUpdateFeatureSetting,
} from "../../../utils/useFeatureSettings";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import { NoteContent } from "./ClientNoteWidget";
import { ClientOnboardingStatusDetails } from "./ClientOnboardingWidget";
import { widgetProps } from "./config";

interface NoteForm {
  content: string;
}

function EditNoteModal({
  content,
  hasInitialContent,
  isOpened,
  onClose,
}: {
  content?: string;
  hasInitialContent: boolean;
  isOpened: boolean;
  onClose: () => void;
}) {
  const { getInputProps, onSubmit, reset, setFieldValue, values } =
    useForm<NoteForm>({
      initialValues: {
        content: content ?? "",
      },
    });

  const { createFeatureSetting } = useCreateFeatureSetting();
  const { updateFeatureSetting } = useUpdateFeatureSetting();

  useEffect(() => {
    if (content) {
      setFieldValue("content", content);
    }
  }, [content, setFieldValue]);

  function onSubmitForm(values: NoteForm) {
    const payload = {
      role: IdentityRole.Client,
      featureId: FeatureId.ClientNote,
      enabled: true,
      value: {
        featureId: FeatureId.ClientNote,
        content: values.content,
      },
    } satisfies FeatureSettingUpsert;

    const showNotif = () => {
      notifications.show({
        message: `Client Quick Share changes saved! The client can now view this note.`,
        icon: <IconNote width={16} />,
        color: "green",
        autoClose: 2000,
      });
      onClose();
    };

    if (hasInitialContent) {
      updateFeatureSetting(payload, {
        onSuccess() {
          showNotif();
        },
      });
    } else {
      createFeatureSetting(payload, {
        onSuccess() {
          showNotif();
        },
      });
    }
  }

  function cleanupForm() {
    reset();
    onClose();
  }

  return (
    <Modal
      opened={isOpened}
      onClose={cleanupForm}
      onClick={(e) => e.stopPropagation()}
      title={
        <Group gap="xs">
          <IconNote />
          <Title order={3} fw="bold">
            Client Quick Share
          </Title>
        </Group>
      }
      centered
      size="xl"
    >
      <form onSubmit={onSubmit(onSubmitForm)}>
        <Stack gap="xs">
          <Text size="sm" c="dimmed">
            Notes, links, and updates for your client.
          </Text>
          <Card p={0}>
            <RichTextEditor
              content={values.content}
              onSave={(value) => setFieldValue("content", value)}
              editorProps={{ ...getInputProps("content") }}
            />
          </Card>
        </Stack>
      </form>
    </Modal>
  );
}

function Content({ onboardingStatus }: { onboardingStatus: JSX.Element }) {
  const navigate = useNavigate();

  const [isNoteModalOpened, { open: openNoteModal, close: closeNoteModal }] =
    useDisclosure();

  const { isClientModeEnabled } = useClientMode();

  const clientModeToggledLabel = isClientModeEnabled ? "active" : "disabled";

  return (
    <>
      <Stack gap="xs">
        <Text c="dimmed" size="sm">
          Client Mode is <b>{clientModeToggledLabel}</b> for your client. To
          customize it, configure the{" "}
          <Anchor href="/account/project/client-mode">Client Mode</Anchor>{" "}
          section.
        </Text>
        <Group gap="xs" wrap="nowrap" align="flex-start">
          <Title order={5}>Onboarding form</Title>
          <Tooltip withArrow label="Preview form">
            <ActionIcon
              variant="transparent"
              color="base.6"
              size="sm"
              onClick={() => navigate("/client/welcome")}
            >
              <IconEye />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Text c="dimmed" size="sm">
          Configure the Onboarding form in the{" "}
          <Anchor href="/account/project/blocks">Blocks</Anchor> section.
        </Text>
        {onboardingStatus}
      </Stack>
      <Divider />
      <Stack gap="xs">
        <Title order={5}>Quick Share</Title>
        <Text c="dimmed" size="sm">
          This is a shared space where you can post notes, links, and updates
          for your client to see.
        </Text>
        <NoteContent
          icon={<IconEdit />}
          iconTooltip="Edit note"
          noContentElement={
            <QuickLinkButton label="Add note" onClick={openNoteModal} />
          }
          additionalElement={({ content, hasInitialContent }) => (
            <EditNoteModal
              content={content}
              hasInitialContent={hasInitialContent}
              isOpened={isNoteModalOpened}
              onClose={closeNoteModal}
            />
          )}
          onClickCard={openNoteModal}
        />
      </Stack>
    </>
  );
}

export function ClientModeWidget() {
  const { hasPermission } = useIsGranted({
    permission: "widget.read.client-mode",
  });
  const {
    allBlockPathsCompleted,
    clientBlocks,
    isAllCompleted,
    isNoneCompleted,
  } = useClientMode();

  const clientModeStatus = useMemo(
    () => (
      <ClientOnboardingStatusDetails
        isAllCompleted={isAllCompleted}
        isNoneCompleted={isNoneCompleted}
        completed={allBlockPathsCompleted.length}
        total={clientBlocks.length}
      />
    ),
    [
      allBlockPathsCompleted.length,
      clientBlocks.length,
      isAllCompleted,
      isNoneCompleted,
    ],
  );

  if (!hasPermission) return null;

  return (
    <BaseWidget title={widgetProps[WidgetId.ClientMode].label} disabled>
      <Content onboardingStatus={clientModeStatus} />
    </BaseWidget>
  );
}
