import { z } from "zod";
import { IdentityRole } from "./Identity";

export enum FeatureId {
  BuildMode = "build-mode",
  CreateMode = "create-mode",
  DashboardAssistant = "dashboard-assistant",
  Dashboard = "dashboard",
  News = "news",
  Tasks = "tasks",
  ClientNote = "client-note",
}

const FeatureSettingValueSchema = z.discriminatedUnion("featureId", [
  z.object({
    featureId: z.literal(FeatureId.ClientNote),
    content: z.string(),
  }),
]);

export const FeatureSettingFiltersSchema = z.object({
  role: z.nativeEnum(IdentityRole).optional(),
  featureId: z.nativeEnum(FeatureId).optional(),
  enabled: z.boolean().optional(),
});

export const FeatureSettingGetSchema = z.object({
  role: z.nativeEnum(IdentityRole),
  featureId: z.nativeEnum(FeatureId),
});

export const FeatureSettingUpsertSchema = z.object({
  role: z.nativeEnum(IdentityRole),
  featureId: z.nativeEnum(FeatureId),
  enabled: z.boolean().optional(),
  value: FeatureSettingValueSchema.optional(),
});

export type FeatureSettingFilters = z.infer<typeof FeatureSettingFiltersSchema>;
export type FeatureSettingGet = z.infer<typeof FeatureSettingGetSchema>;
export type FeatureSettingUpsert = z.infer<typeof FeatureSettingUpsertSchema>;
export type FeatureSettingValue = z.infer<typeof FeatureSettingValueSchema>;
