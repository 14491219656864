import { Card, Group, Modal, ThemeIcon, Title } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import { MarkdownContent } from "../MarkdownContent";
import classes from "./QuickViewModal.module.css";

export function QuickViewModal({
  title,
  content,
  isOpened,
  onClose,
}: {
  title: string;
  content: string;
  isOpened: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      centered
      opened={isOpened}
      lockScroll={false}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      title={
        <Group gap="xs">
          <ThemeIcon variant="transparent" className={classes.icon}>
            <IconEye />
          </ThemeIcon>
          <Title order={3} fw="bold">
            {title}
          </Title>
        </Group>
      }
      size="xl"
    >
      <Card className={classes.contentCard}>
        <MarkdownContent content={content} />
      </Card>
    </Modal>
  );
}
