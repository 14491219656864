import { FeatureId, IdentityRole } from "core";
import { useMemo } from "react";
import { useAuthContext } from "../auth/useAuthContext";
import { useIsRole } from "../auth/useIdentity";
import { useDashboard } from "../pages/Dashboard/useDashboard";
import { useAllowedBlocks } from "./useFacts";
import {
  isFeatureSettingEnabled,
  useFeatureSettings,
} from "./useFeatureSettings";
import { useOrganization } from "./useOrganization";

export function useClientMode() {
  const isClient = useIsRole(IdentityRole.Client);
  const { blocks: clientBlocks = [] } = useAllowedBlocks({
    role: IdentityRole.Client,
  });
  const { facts } = useDashboard();
  const { identity } = useAuthContext();
  const { organization, isLoading: isLoadingOrg } = useOrganization();
  const { settings } = useFeatureSettings({
    role: identity.primaryRole,
  });
  const orgLogo = organization?.clientLogoUrl;

  const allBlockPathsCompleted = useMemo(
    () =>
      facts
        .flatMap((f) => f.blockPath)
        .filter((blockPath) => clientBlocks.includes(blockPath)),
    [clientBlocks, facts],
  );
  const isOnboardingSet = clientBlocks.length > 0;
  const isAllCompleted =
    isOnboardingSet && clientBlocks.length === allBlockPathsCompleted.length;
  const isNoneCompleted =
    isOnboardingSet && allBlockPathsCompleted.length === 0;
  const nextIncompleteBlock = clientBlocks.find(
    (b) => !allBlockPathsCompleted.includes(b),
  );
  const isClientModeEnabled = !!organization?.settings.clientMode?.isEnabled;
  const isClientModeOnboardingDone =
    !!organization?.settings.clientMode?.isOnboardingCompleted;

  /**
   * We consider the onboarding done:
   * - when the user is not a client
   * - or when the user is a client
   *    - all the onboarding blocks set are completed
   *    - or the client onboarding was completed for the first time, as the owners can add later more onboarding blocks
   */
  const isOnboardingDone =
    !isClient ||
    (isClient &&
      isClientModeEnabled &&
      (isAllCompleted || isClientModeOnboardingDone));

  return {
    isClientModeEnabled,
    clientBlocks,
    allBlockPathsCompleted,
    nextIncompleteBlock,
    isClient,
    isAllCompleted,
    isNoneCompleted,
    isClientModeOnboardingDone,
    isLoadingOrg,
    orgLogo,
    isOnboardingDone,

    /**
     * Settings by default DISABLED for clients when not saved in DB
     */
    isDashboardEnabled:
      !isClient || isFeatureSettingEnabled(FeatureId.Dashboard, settings),
    isDashboardAssistantEnabled:
      isOnboardingDone &&
      isFeatureSettingEnabled(FeatureId.DashboardAssistant, settings),
    isCreateModeEnabled:
      !isClient ||
      (isOnboardingDone &&
        isFeatureSettingEnabled(FeatureId.CreateMode, settings)),
    isBuildModeEnabled:
      !isClient ||
      (isOnboardingDone &&
        isFeatureSettingEnabled(FeatureId.BuildMode, settings)),
  };
}
