import { WidgetId } from "core";
import {
  BuildWidget,
  ClientModeWidget,
  ClientNoteWidget,
  ClientOnboardingWidget,
  CreateWidget,
  NewsWidget,
  SalesWidget,
  SitePerformanceWidget,
  TasksWidget,
  TeamWidget,
} from ".";

export type Widget = {
  id: WidgetId;
  type: "fixed" | "flexible";
  content: JSX.Element;
};

export const widgetContent: Record<WidgetId, JSX.Element> = {
  [WidgetId.Build]: <BuildWidget />,
  [WidgetId.Create]: <CreateWidget />,
  [WidgetId.News]: <NewsWidget />,
  [WidgetId.Team]: <TeamWidget />,
  [WidgetId.Tasks]: <TasksWidget />,
  [WidgetId.Sales]: <SalesWidget />,
  [WidgetId.SitePerformance]: <SitePerformanceWidget />,
  [WidgetId.ClientMode]: <ClientModeWidget />,
  [WidgetId.ClientOnboarding]: <ClientOnboardingWidget />,
  [WidgetId.ClientNote]: <ClientNoteWidget />,
};

export const widgetProps = {
  [WidgetId.Build]: {
    label: "Knowledge",
    description: "",
  },
  [WidgetId.Create]: { label: "Create", description: "" },
  [WidgetId.News]: {
    label: "News",
    description:
      "Stay updated with the latest industry trends and announcements.",
  },
  [WidgetId.Tasks]: {
    label: "Tasks",
    description:
      "Track and manage your daily to-do items for streamlined productivity.",
  },
  [WidgetId.Team]: {
    label: "Latest Updates",
    description:
      "Get real-time updates on changes or new activities in your project.",
  },
  [WidgetId.Sales]: {
    label: "Sales",
    description:
      "Monitor your sales metrics and performance in a single glance.",
  },
  [WidgetId.SitePerformance]: {
    label: "Website Analytics",
    description: "Power your decisions with Google Analytics.",
  },
  [WidgetId.ClientMode]: {
    label: "Client Mode",
    description: "",
  },
  [WidgetId.ClientOnboarding]: {
    label: "Business Details",
    description: "",
  },
  [WidgetId.ClientNote]: {
    label: "Quick Share",
    description: "",
  },
};
