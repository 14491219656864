import classes from "./StackIcons.module.css";

export function LandManagementIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.332 178.923L66.7724 115.916C63.385 110.078 61.6198 103.441 61.6593 96.6917C61.7382 83.245 68.9432 70.849 80.5884 64.1251L82.5566 62.9886C95.412 55.5659 111.251 55.566 124.106 62.9887L126.096 64.1377C137.718 70.8483 144.878 83.2492 144.878 96.6696C144.878 103.244 143.153 109.704 139.874 115.403L103.332 178.923ZM121.475 98.0001C121.475 107.941 113.416 116 103.475 116C93.5342 116 85.4754 107.941 85.4754 98.0001C85.4754 88.0589 93.5342 80.0001 103.475 80.0001C113.416 80.0001 121.475 88.0589 121.475 98.0001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.512 154.363L205.513 69.8125C205.513 61.2382 200.938 53.3151 193.513 49.0279L115.244 3.83957C107.819 -0.447512 98.6701 -0.4475 91.2445 3.8396L18.0204 46.1144C7.47311 52.2038 0.975606 63.4576 0.975586 75.6366L0.975634 160.188C0.975654 168.762 5.55002 176.685 12.9756 180.972L91.2437 226.16C98.6693 230.448 107.818 230.448 115.244 226.16L188.467 183.885C199.014 177.795 205.512 166.542 205.512 154.363ZM99.2444 18.9023L22.0203 63.4865C19.5451 64.9155 18.0203 67.5566 18.0203 70.4147L18.0203 159.585C18.0203 162.443 19.5451 165.084 22.0203 166.513L99.2439 211.098C101.719 212.527 104.769 212.527 107.244 211.098L184.467 166.513C186.942 165.084 188.467 162.443 188.467 159.585L188.468 70.4156C188.468 67.5575 186.943 64.9164 184.468 63.4873L107.244 18.9023C104.769 17.4733 101.72 17.4733 99.2444 18.9023Z"
        fill="currentColor"
      />
    </svg>
  );
}
